module controllers {
    export module master {

        interface IPermitControllerScope extends ng.IScope {
        }

        interface IPermitParams extends ng.ui.IStateParamsService {
        }

        export class permitController {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'permitService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'menuService'
            ];

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IPermitDisplay>;
            searchAccordian: boolean = false;
            myHook: any;
            HasEditPermitRight: boolean = false;

            perSearch: interfaces.master.IPermitSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            sortName: string;
            sortDirection: string;

            dropdownsObject = {
                PermitStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            selected: uiGrid.IGridRow;
            selectedRowsArray: Array<interfaces.master.IPermitDisplay> = [];



            filterNames: string[] = [ "EntityCode",
            "RestrictionCountryCode",
            "Restriction",
            "RestrictionType",
            "Permit",
            "StartDate",
            "EndDate",
            "Status",
            "SLADate",
            "QuotaControlled",];

        filterList: interfaces.applicationcore.IKeyValue[] = [];

            constructor(private $scope: IPermitControllerScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private permitService: interfaces.master.IPermitService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPermitParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private menuService: interfaces.applicationcore.IMenuService
            ) {
                this.perSearch = {
                    PermitStatusId: -1,
                };
                this.loadPermits();
                this.getPermitStatusDropdown();
                this.getHasEditPermitRight();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Permit',
                    from: 'auth.MasterData.Permit.**'
                }, () => {
                    this.loadPermits();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }
            
            loadPermits() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.perSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }
                        
                        return this.permitService.getList().query(params, (result: Array<interfaces.master.IPermitDisplay>) => {
                            this.gvwPermits.data = result;
                            this.selectedRowsArray = [];

                            if (result[0]) {
                                this.gvwPermits.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwPermits.totalItems = 0;
                            }

                            this.$timeout(() => {
                                if (this.gridApi) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                            this.$timeout(() => {
                                if (this.selected) {
                                    var index = _.findIndex(this.gvwPermits.data, (o : interfaces.master.IPermitDisplay) => { return o.Id === this.selected.entity.Id });
                                    this.gridApi.selection.selectRow(this.gvwPermits.data[index]);
                                }
                            });
        

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            getPermitStatusDropdown() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({
                    type: Enum.EnumStatusType.Permit
                }, (result) => {
                    this.dropdownsObject.PermitStatusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            
            getHasEditPermitRight() {
                this.HasEditPermitRight = false;
                return this.menuService.getGTSConnectMenuItem(617).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.HasEditPermitRight = true;
                    }
                }).$promise;
            }

            public gvwPermits: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'PermitList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi)
                    this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.gridApi.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.SLAStatus === 1, 'SLA-danger': row.entity.SLAStatus === 2 }" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.permitController.editPermit(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "RestrictionCountryCode",
                    displayName: "Restriction Country",
                    field: "RestrictionCountryCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "Restriction",
                    displayName: "Restriction",
                    field: "Restriction",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "RestrictionType",
                    displayName: "Restriction Type",
                    field: "RestrictionType",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "Permit",
                    displayName: "Permit",
                    field: "Permit",
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "StartDate",
                    displayName: "Start Date",
                    field: "StartDate",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="StartDate"></gts-date>
                                
                    </div>`,
                }, {
                    name: "EndDate",
                    displayName: "End Date",
                    field: "EndDate",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="EndDate"></gts-date>
                                
                    </div>`,
                }, {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Description}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "SLADate",
                    displayName: "SLA Date",
                    field: "SLADate",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLADate"></gts-date>
                                
                    </div>`,
                }, {
                    name: "QuotaControlled",
                    displayName: "Quota Controlled",
                    field: "IsQuotaControlled",
                    width: 132,
                    cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                        <input type="checkbox" ng-disabled="false" ng-model="colFilter.term"  ng-model-options="{ debounce: 500 }" />
                        </div>
                    </div>`
                },  {
                    name: "NUMRECS",
                    displayName: "",
                    field: "NumRecs",
                    visible: false
                }]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.DoSearch();

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });

                this.gridApi.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;

                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                Exists = 1;
                            }
                        }

                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }
                    }
                });

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });

                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            public editPermit(id: number) {
                this.$state.go("auth.MasterData.Permit.Update", { permId: id });
            }

            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.master.IPermitDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            addNew() {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Permits - Add New Permit</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="permit.add">
                            <form name="permitAdd">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Owner Entity</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="ownerEntity" ng-model="addNewCtrl.selectedEntity" form="inputForm" load-data="addNewCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Country</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="country" ng-model="addNewCtrl.selectedCountry" form="inputForm" load-data="addNewCtrl.loadCountries()" required="true" ng-change="addNewCtrl.onCountryChange()" ng-disabled="!addNewCtrl.selectedEntity"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Restriction</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="restriction" ng-model="addNewCtrl.selectedRestriction" list="addNewCtrl.listRestriction" ng-disabled="!addNewCtrl.selectedCountry" form="inputForm" load-data="addNewCtrl.loadRestrictions()" required="true" ng-model-options="{ debounce: 500 }"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Description</label>
					                            </div>
					                            <div class="col-md-6">
						                            <p class="input-group-sm has-feedback" ng-class="{'has-error': addNewCtrl.description.$invalid}">
                                                        <input type="text" name="description" ng-model="addNewCtrl.description" class="form-control" ng-required="addNewCtrl.description" />
                                                    </p>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Permit Number</label>
					                            </div>
					                            <div class="col-md-6">
						                            <p class="input-group-sm has-feedback" ng-class="{'has-error': addNewCtrl.permitNumber.$invalid}">
                                                        <input type="text" name="permitNumber" ng-model="addNewCtrl.permitNumber" class="form-control" ng-required="addNewCtrl.permitNumber" />
                                                    </p>
					                            </div>
				                            </div>
			                            </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" type="button" ng-click="addNewCtrl.add()">Add New</button>
                                        <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class addNewCtrl {
                        selectedEntity: interfaces.applicationcore.IDropdownModel;
                        selectedCountry: interfaces.applicationcore.IDropdownModel;
                        selectedRestriction: interfaces.applicationcore.IDropdownModel;
                        description: string;
                        permitNumber: string;
                        listRestriction: Array<interfaces.applicationcore.IDropdownModel> = [];
                        messages: interfaces.applicationcore.IMessage[];

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private permitService: interfaces.master.IPermitService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                        ) {
                            this.selectedEntity = <interfaces.applicationcore.IDropdownModel>{
                                Id: $rootScope.Entity.Id,
                                Code: $rootScope.Entity.Code,
                                Description: $rootScope.Entity.Name,
                                Display: $rootScope.Entity.Code + " - " + $rootScope.Entity.Name,
                                Selected: true
                            };
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        add() {
                            var saveItem: interfaces.master.IPermitCreate = <interfaces.master.IPermitCreate>{
                                Entity: this.selectedEntity,
                                Restriction: this.selectedRestriction,
                                PermitNumber: this.permitNumber,
                                Description: this.description,
                            };
                            this.permitService.createNewPermit().save(saveItem, (result) => {
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$state.go("auth.MasterData.Permit.Update", { permId: result.ID });
                                }

                                this.$uibModalInstance.close(true);
                            }, (errorResult) => {
                                this.generalService.displayMessageHandler(errorResult.data);

                                if (errorResult.data.Messages) {
                                    this.messages = [];
                                }

                                angular.forEach(errorResult.data.Messages, (o) => {
                                    angular.forEach(o.MessageItems, (n) => {
                                        this.messages.push(n);
                                    });
                                });
                            });
                        }
                        
                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }

                        loadCountries() {
                            return this.permitService.getRequiredProductCountryDropdown(this.selectedEntity.Id).query({
                                }, () => {
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                        }

                        onCountryChange() {
                            this.selectedRestriction = null;
                            this.listRestriction = [];
                        }

                        loadRestrictions() {
                            return this.permitService.getRestrictionsDropdown().query({
                                countryId: this.selectedCountry.Id
                            }, () => {
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: {
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadPermits();
                    }
                 });
            }

            printDocuments() {
                if (this.gridApi && this.gridApi.selection.getSelectedRows().length > 0) {
                    var selected = this.gridApi.selection.getSelectedRows()[0];
                    this.$state.go("auth.MasterData.Permit.Update.Documents", { permId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a permit", Enum.EnumMessageType.Warning);
                }
            }

            showAll_click() {
                
                this.searchAccordian = false;

                this.perSearch = {
                    PermitStatusId: 0,
                };

                if (this.gridApi !== undefined) {
                    this.gridApi.grid.clearAllFilters(true, true, true).then(() => {
                        this.gridApi.grid.resetColumnSorting(null);
                    });
                } 

                this.DoSearch();
            }

            search_click() {
                this.DoSearch();
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }
                        
                        angular.forEach(this.perSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if(n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        ///////////////////////////////
                        return this.permitService.getList().query(params, (result: Array<interfaces.master.IPermitDisplay>) => {
                            //////////////////////////////
                            var currentDirtyRows: Array<interfaces.master.IPermitDisplay> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.master.IPermitDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });
                            this.selectedRowsArray = [];
                            this.gvwPermits.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwPermits.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwPermits.totalItems = 0;
                            }

                            this.$timeout(() => {
                                if (this.gridApi) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                            this.$timeout(() => {
                                if (this.selected) {
                                    var index = _.findIndex(this.gvwPermits.data, (o: interfaces.master.IPermitDisplay) => { return o.Id === this.selected.entity.Id });
                                    this.gridApi.selection.selectRow(this.gvwPermits.data[index]);
                                }
                            });

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }
        }

        angular.module("app").controller("permitController", controllers.master.permitController);
    }
}